/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Shaba = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Web Font Loader
        // Documentation: https://github.com/typekit/webfontloader
        //[ 'Muli::latin' ]
         WebFontConfig = {
           google: { families: [ 'Questrial::latin' ] }
         };
         (function() {
           var wf = document.createElement('script');
           wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
           wf.type = 'text/javascript';
           wf.async = 'true';
           var s = document.getElementsByTagName('script')[0];
           s.parentNode.insertBefore(wf, s);
         })();

         jQuery(window).load(function(){
              // Detect header + wrap height
              var headerHeight = jQuery('.navbar-brand').height();
              var wrapHeight = jQuery('.wrap').height();
              var footerHeight = jQuery('footer').height();
              windowHeight = jQuery(window).height();
              if((headerHeight + wrapHeight + footerHeight) < windowHeight) {
                  jQuery('footer').css({'position':'fixed','bottom':'0','left':'0','right':'0'});
              } else {
                  jQuery('footer').css({'position':'static'});
              }
          });
          jQuery(window).resize(function(){
              headerHeight = jQuery('.navbar-brand').height();
              wrapHeight = jQuery('.wrap').height();
              footerHeight = jQuery('footer').height();
              windowHeight = jQuery(window).height();
              if((headerHeight + wrapHeight + footerHeight) < windowHeight) {
                  jQuery('footer').css({'position':'fixed','bottom':'0','left':'0','right':'0'});
              } else {
                  jQuery('footer').css({'position':'static'});
              }
          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Shaba;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.